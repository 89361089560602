<template>
  <div class="login-m">
    <div class="login-logo">
      <img src="../../assets/img/login/top-logo.png" alt="" />
    </div>
    <div class="wx-login-panel">
      <div class="wx-button" @click="wxLogin">
        <img src="../../assets/img/login/weixin.png" alt="" /><span
          >微信登录</span
        >
      </div>
      <div class="agr-cell">
        <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
        <div class="agr-label">
          <span>《用户协议》</span><span>《隐私政策》</span
          ><span>《版权保护》</span>
        </div>
      </div>
      <div class="login-foot">
        <div class="other-hd"><span>其他登录方式</span></div>
        <div class="login-other">
          <div class="col">
            <router-link to="/loginCode" class="login-item">
              <img src="../../assets/img/login/safe-icon.png" alt="" />
              <div class="l-label">验证码登录</div>
            </router-link>
          </div>
          <div class="col">
            <router-link to="/loginPassword" class="login-item">
              <img src="../../assets/img/login/lock-icon.png" alt="" />
              <div class="l-label">密码登录</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getWechatLoginParams, wechatLogin } from "@/service/login";
export default {
  data() {
    return {
      checked: false,
      appId: "",
    };
  },
  mounted() {
    this.code = this.getUrlCode().code;

    // getWechatLoginParams({ url: window.location.href.split("#")[0] }).then(
    //   (res) => {
    //     var wxLoginParams = res.result;
    //     this.appId = wxLoginParams.appId;
    //     // jWeixin.config({
    //     // debug: true,
    //     // appId: wxLoginParams.appId, // 必填，企业微信的corpID
    //     // timestamp: wxLoginParams.timestamp, // 必填，生成签名的时间戳
    //     // nonceStr: wxLoginParams.nonceStr, // 必填，生成签名的随机串
    //     // signature: wxLoginParams.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
    //     // jsApiList: ["closeWindow", "scanQRCode", "chooseImage"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //     // });
    //   }
    // );
    if (this.code) {
      wechatLogin({ code: this.code }).then((loginres) => {
        if (loginres.success) {
          Toast("登录成功");
          window.localStorage.setItem("CSON_PAGE_TOKEN", loginres.result.token);
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        } else {
          Toast(loginres.message);
        }
      });
    }
  },
  methods: {
    wxLogin() {
      if (!this.checked) {
        return Toast("请先勾选相关协议!");
      }
      console.log(jWeixin);
      // jWeixin.login({
      //   success: function (wxLoginRes) {
      //     if (wxLoginRes.code) {
      //       wechatLogin({ code: wxLoginRes.code }).then((loginres) => {
      //         if (loginres.success) {
      //           Toast("登录成功");
      //           window.localStorage.setItem(
      //             "CSON_PAGE_TOKEN",
      //             loginres.result.token
      //           );
      //           setTimeout(() => {
      //             window.location.href = "/";
      //           }, 1500);
      //         } else {
      //           Toast(loginres.message);
      //         }
      //       });
      //     } else {
      //       Toast(wxLoginRes.errMsg);
      //     }
      //   },
      // });
      var appId = this.appId; // 替换为你的 AppID
      var redirectUri = encodeURIComponent(window.location.href);
      var scope = "snsapi_userinfo"; // snsapi_base 或 snsapi_userinfo
      var wechatAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${"wx74aa81b1f29d9765"}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}#wechat_redirect`;

      window.location.href = wechatAuthUrl;
    },
    getUrlCode() {
      // 截取url中的code方法
      const url = location.search;
      // eslint-disable-next-line no-new-object
      const theRequest = new Object();
      if (url.indexOf("?") != -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
};
</script>
<style scoped lang="scss">
.login-logo {
  padding: 20px 0;
  text-align: center;
  font-size: 0;
  img {
    height: 40px;
  }
}
.wx-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  border-radius: 16px;
  line-height: 1;
  background-color: #00c802;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
  img {
    width: 24px;
    margin-right: 10px;
  }
}
.wx-login-panel {
  background: #ffffff;
  border-radius: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 30px 20px;
}
.login-m {
  min-height: 100vh;
  margin-top: 50px;
  background: #ebeff4 url(../../assets/img/login/login-m-bg.jpg) center top
    no-repeat;
  background-size: 100% 200px;
}
.other-hd {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.other-hd span {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 0 20px;
  font-size: 14px;
  display: inline-block;
}
.other-hd::after {
  height: 1px;
  background-color: #999999;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
}
.login-other {
  display: flex;

  .col {
    flex: 1;
    text-align: center;
  }
  .login-item img {
    width: 40px;
  }
  .login-item .l-label {
    color: #666;
    font-size: 14px;
    margin-top: 10px;
  }
}
.agr-cell ::v-deep .el-checkbox__label {
  color: #666 !important;
  font-size: 12px;
}
.agr-label {
  color: #007bf7;
  font-size: 12px;
}
.agr-cell {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 30px;
}
</style>
